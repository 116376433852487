import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const MenuMain = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allDataYaml {
          nodes {
            navLinks {
              href
              title
            }
            pinnedLinks {
              path
              title
            }
          }
        }
      }
    `
  )
  const { pinnedLinks, navLinks } = data.allDataYaml.nodes[0]
  return (
    <div className="menu-main">
      {pinnedLinks && (
        <div className="menu-main-pinned">
          <ul>
            {pinnedLinks.map((link, i) => (
              <li key={`pinned-${i}`}>
                <Link to={link.path}>{link.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {navLinks && (
        <div className="menu-main-nav">
          <ul>
            {navLinks.map((link, i) => (
              <li key={`nav-${i}`}>
                {link.path && <Link to={link.path}>{link.title}</Link>}
                {!link.path && link.href && (
                  <a href={link.href}>{link.title}</a>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default MenuMain

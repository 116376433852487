import React from "react"
import ktsLogoUrl from "../../content/assets/logo-kts-footer.png"
import imaginaryLogoUrl from "../../content/assets/imaginary-logo.svg"
import { graphql, Link, useStaticQuery } from "gatsby"

export default function Footer() {
  const data = useStaticQuery(
    graphql`
      query {
        allDataYaml {
          nodes {
            navLinks {
              href
              title
            }
            footerLinks {
              path
              href
              title
            }
          }
        }
      }
    `
  )
  const { footerLinks, navLinks } = data.allDataYaml.nodes[0]

  return (
    <footer>
      <div className="footer-top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-6 col-lg-3 col-md-4">
              Developed by
              <img
                className="logo-imaginary mt-3"
                src={imaginaryLogoUrl}
                alt="IMAGINARY"
              />
            </div>
            <div className="col-6 col-lg-3 col-md-4">
              Supported by
              <img
                className="logo-kts"
                src={ktsLogoUrl}
                alt="Klaus Tschira Stiftung gemeinnützige GmbH"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              {footerLinks && (
                <div className="menu-footer-nav">
                  <ul>
                    {footerLinks.map((link, i) => (
                      <li key={`footer-nav-2-${i}`}>
                        {link.path && <Link to={link.path}>{link.title}</Link>}
                        {!link.path && link.href && (
                          <a href={link.href}>{link.title}</a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="col-md-3">
              {navLinks && (
                <div className="menu-footer-nav">
                  <ul>
                    {navLinks.map((link, i) => (
                      <li key={`footer-nav-${i}`}>
                        {link.path && <Link to={link.path}>{link.title}</Link>}
                        {!link.path && link.href && (
                          <a href={link.href}>{link.title}</a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {`©${new Date().getFullYear()} IMAGINARY gGmbH`}
              <br />
              <small>
                Built with {` `}
                <a href="https://www.gatsbyjs.org">Gatsby</a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

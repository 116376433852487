import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MenuMain from "../components/menu-main"

export default function PrivacyPolicy({ location }) {
  return (
    <Layout title="Privacy Policy" location={location} sidebar={<MenuMain />}>
      <SEO title="Privacy Policy" />
      <div>
        <h1>Privacy Policy</h1>
        <p>This website collects some Personal Data from its Users.</p>
        <h2>Access logs</h2>
        <p>
          Access logs containing users' IP addresses may be collected for
          security monitoring and monitoring of website traffic.
        </p>
        <h2>Personal Data protection</h2>
        <p>
          No personal data collected by this website will ever be shared with
          third parties or used for purposes other than indicated.
        </p>
        <h2>Contact Information</h2>
        <p>
          The I AM A.I. website runs under the responsibility of IMAGINARY
          gGmbH.
        </p>
        <p>
          IMAGINARY gGmbH
          <br />
          Mittenwalder Straße 48
          <br />
          10961 Berlin
          <br />
          Germany
          <br />
          <a href="http://www.imaginary.org/">www.imaginary.org</a>
        </p>
      </div>
    </Layout>
  )
}

import React, { useState } from "react"
import { Link } from "gatsby"

import faviconUrl from "../../content/assets/favicon.png"
import logoUrl from "../../content/assets/10mm-logo.svg"
import imaginaryLogoUrl from "../../content/assets/imaginary-logo-project.svg"
import barsIcon from "../../content/assets/fontawesome/bars-solid.svg"
import Footer from "./footer"
import { Helmet } from "react-helmet/es/Helmet"

const Layout = ({ location, title, children, sidebar }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let siteName
  const [sidebarShown, setSidebarShown] = useState(false)

  const toggleSidebar = () => {
    setSidebarShown(!sidebarShown)
  }

  const siteLogo = (
    <Link to="/">
      <img src={logoUrl} alt="10 Minute Museum" className="logo-site" />
    </Link>
  )

  if (location.pathname === rootPath) {
    siteName = <h1 className="site-name">{siteLogo}</h1>
  } else {
    siteName = <h3 className="site-name">{siteLogo}</h3>
  }
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <header>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-6 col-8">{siteName}</div>
            <div className="col-lg-3 col-md-4 d-none d-md-block">
              <a href="http://about.imaginary.org/" className="logo-imaginary">
                <img src={imaginaryLogoUrl} alt="A project by IMAGINARY" />
              </a>
            </div>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              <img src={barsIcon} alt="Menu" />
            </button>
          </div>
        </div>
      </header>
      <main>
        <div className="container">
          <div className="row">
            <div
              className={`col-md-4 col-sidebar${
                sidebarShown ? " visible" : ""
              }`}
            >
              {sidebar}
            </div>
            <div className="col-md-8">
              <main>{children}</main>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Layout

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MenuMain from "../components/menu-main"

export default function Impressum({ location }) {
  return (
    <Layout title="Impressum" location={location} sidebar={<MenuMain />}>
      <SEO title="Impressum" />
      <div>
        <h1>Impressum</h1>
        <p>
          <strong>
            Responsible for this web site:
            <br />
          </strong>
          IMAGINARY gGmbH
          <br />
          Mittenwalder Straße 48
          <br />
          10961 Berlin
          <br />
          Germany
          <br />
          <a href="http://www.imaginary.org/">www.imaginary.org</a>
        </p>
        <p>
          <strong>
            Director:
            <br />
          </strong>
          Dr. Andreas Matt
          <br />
          registered at register court Berlin-Charlottenburg,
          <br />
          HRB Nr. 180266 B
        </p>
        <p>
          <strong>
            Sales tax identification number:
            <br />
          </strong>
          DE308694794
        </p>
        <p>
          <strong>
            Person responsable for the content according to sec. 55 para. 2
            RStV:
            <br />
          </strong>
          Dr. Andreas Matt
          <br />
          IMAGINARY gGmbH
          <br />
          Mittenwalder Straße 48
          <br />
          10961 Berlin
          <br />
          Germany
        </p>
        <h2>Disclaimer</h2>
        <p>
          Despite careful checks and revisions of the content of this website we
          do not guarantee that the information presented is complete, accurate
          or up-to-date. We reserve the right to change or update the
          information on this website at any time and without prior
          announcement.
        </p>
        <p>
          This website contains links to other sites. IMAGINARY gGmbH is not
          responsible or liable for the content of websites accessed via
          external links.
        </p>
        <p>
          For questions, suggestions and technical issues please contact
          <a href="mailto:info@imaginary.org">info@imaginary.org</a>.
        </p>
      </div>
    </Layout>
  )
}
